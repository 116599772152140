/*import React, { lazy, Suspense } from 'react' */
import React, { lazy} from 'react'
import { BsWhatsapp } from "react-icons/bs"
import { FiChevronUp } from "react-icons/fi"
import { useScrollPosition } from '../hooks/useScrollPosition'

const Content1 = lazy(() => import('./home/Content1'))
const AboutUs = lazy(() => import('./home/AboutUs'))
const OurHistory = lazy(() => import('./home/OurHistory'))
const LiveStreaming = lazy(() => import('./our-service/LiveStreaming'))
const PhotoVideoGraphy = lazy(() => import('./our-service/PhotoVideoGraphy'))
const DigitalDesigner = lazy(() => import('./our-service/DigitalDesigner'))
const OurTeam = lazy(() => import('./home/OurTeam'))
const OurClient = lazy(() => import('./home/OurClient'))

const Home = () => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const scrollPosition = useScrollPosition()
    return (
        <>

            {/* home */}
            <Content1 />
            {/* about us */}
            <AboutUs />
            {/* our history & Progress */}
            <OurHistory />
            {/* Our Service */}
            <LiveStreaming />
            <PhotoVideoGraphy />
            <DigitalDesigner />
            {/* Our team */}
            <OurTeam />
            {/* our client */}
            <OurClient />
            {/* button whatsapp and goto home */}
            <a
                className='hidden md:flex transition ease-in-out duration-700 fixed justify-center w-[60px] h-[60px] bottom-[60px] right-[40px] text-center bg-[#25d366] rounded-full opacity-100'
                href={`https://wa.me/085741537274`}
                rel='noreferrer'
                target='_blank'
            >
                <BsWhatsapp className='text-[30px] text-slate-50 ml-[1px] mt-[14px]' />
            </a>
            <a
                className={classNames(
                    scrollPosition > 500
                        ?
                        'hidden md:flex transition ease-in-out duration-700 fixed justify-center w-[60px] h-[60px] bottom-[130px] right-[40px] text-center bg-[#0E2850]  rounded-full opacity-100'
                        : 'scale-0 md:flex transition ease-in-out duration-700 fixed justify-center w-[60px] h-[60px] bottom-[130px] right-[40px] text-center bg-[#0E2850] rounded-full opacity-0'
                )}
                href='#home'
            >
                <FiChevronUp className='text-[30px] text-slate-50 ml-[1px] mt-[14px]' />
            </a>

        </>

    )
}

export default Home