import { useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import Logo from '../assets/logo/vegaslogo.png'
import { useScrollPosition } from '../hooks/useScrollPosition'


const listOurService = [
    {
        name: 'LIVE STREAMING',
        path: '#live-streaming'
    },
    {
        name: 'DIGITAL DESIGNER',
        path: '#digital-designer'
    },
    {
        name: 'PHOTO AND VIDEOGRAPHY',
        path: '#photo-video-graphy'
    },
]

const Navbar = () => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const scrollPosition = useScrollPosition()
    const [navbarOpen, setNavbarOpen] = useState(false)


    return (
        <>
            <nav className={classNames(
                scrollPosition > 100 ? "fixed w-[100%] transition ease-in-out duration-700 bg-[#000814] flex flex-wrap items-center justify-between px-2 py-3 mb-3 z-40" : "fixed w-[100%] transition ease-in-out duration-700 flex flex-wrap items-center justify-between px-2 py-3 bg-transparent mb-3 z-40"
            )}>
                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between z-40">
                    <div className="w-full relative flex items-center justify-between mt-[-50px] mb-[-50px] lg:w-auto lg:static lg:block lg:justify-start">
                        <a
                            className="flex text-sm font-bold"
                            href="#pablo"
                        >
                            <img src={Logo} alt='logo' className='w-[150px]' />
                        </a>
                        <button
                            className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <RxHamburgerMenu />
                        </button>
                    </div>
                    <div
                        className={
                            "lg:flex flex-grow items-center z-10" +
                            (navbarOpen ? " flex" : " hidden")
                        }
                        id="example-navbar-danger"
                    >
                        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center text-md uppercase italic font-bold leading-snug text-white hover:opacity-75"
                                    href="#about-us"
                                >
                                    <span className="ml-2 ">ABOUT US</span>
                                </a>
                            </li>
                            <li className="nav-item group">
                                <div
                                    className="px-3 py-2 flex items-center text-md uppercase italic font-bold leading-snug text-white hover:opacity-75 cursor-pointer"
                                    href="#pablo"
                                >
                                    <span className="ml-2">OUR SERVICES</span>
                                </div>
                                <div className='sm:absolute sm:w-full md:w-auto sm:hidden md:fixed bg-[#000814] md:px-6 py-2 border hidden group-hover:block hover:block'>
                                    <ul className='md:list-disc text-white nav-item'>
                                        {listOurService.map((list, index) =>
                                            <li key={index} className='text-xs font-bold p-1 cursor-pointer hover:opacity-75'>
                                                <a
                                                    href={list.path}
                                                >
                                                    {list.name}
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center text-md uppercase italic font-bold leading-snug text-white hover:opacity-75"
                                    href="#team"
                                >
                                    <span className="ml-2">OUR TEAM</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center text-md uppercase italic font-bold leading-snug text-white hover:opacity-75"
                                    href="#client"
                                >
                                    <span className="ml-2">OUR CLIENT</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar

